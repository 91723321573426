import { Grid } from '@mui/material'
import React from 'react'
import ServiceCard from '../ServiceCard/ServiceCard'

export default function Service() {


  const services = [
    { servicenum: '01', serviceTitle: "Web Development", serviceDescribe: "Ability to design and develop dynamic and interactive websites using modern technologies and basic programming." },
    { servicenum: '02', serviceTitle: "Ui/Ux Design", serviceDescribe: "Designing user interface (UI) and user experience (UX) attractive and efficient to create better interaction of users with digital products." },
    { servicenum: '03', serviceTitle: "Wordpress", serviceDescribe: "Expertise in designing and developing dedicated websites using WordPress content management system for easy content management and updating." },
    { servicenum: '04', serviceTitle: "SEO", serviceDescribe: "Using SEO techniques to increase your website's visibility and ranking in Google search results and other reputable search engines." },
  ]

  return (
    <Grid container style={{ marginTop: "40px" }}>
      {services.map((service) => {
        return <Grid style={{ padding: "24px" }} item md={6} xs={12}>
          <ServiceCard servicenum= {service.servicenum} serviceTitle= {service.serviceTitle}  serviceDescribe= {service.serviceDescribe}  />
        </Grid>

      })}

    </Grid>
  )
}
