import Home from "../Component/Home/Home/Home"
import Service from "../Component/Service/Service/Service"
import Resume from "../Component/Resume/Resume/Resume"
import Contact from "../Component/Contact/Contact/Contact"

const siteRoutes = [
    { path: "/", element: <Home /> },
    { path: "/Home", element: <Home /> },
    { path: "/Services", element: <Service /> },
    { path: "/Resume", element: <Resume /> },
    { path: "/Contact", element: <Contact /> },
]


export default siteRoutes