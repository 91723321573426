import './App.css';
import { useRoutes } from 'react-router';
import siteRoutes from './Routes/Routes.js';
import Header from './Component/Header/Header.js';

function App() {
  const routes = useRoutes(siteRoutes)
  return (
    <div style={{width : "80%" , margin : "auto" }}>
      <Header/>
      {routes}
    </div>
  );
}

export default App;
