import { Grid } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import emailjs from '@emailjs/browser';
import "./Contact.css"
import ContactWay from '../ContactWay/ContactWay'
import { Phone, Email, Place } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    borderRadius: "8px",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



export default function Contact() {
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const form = useRef();


    function firstnameChange(e) {
        setFirstName(e.target.value)
    }
    function lastnameChange(e) {
        setLastName(e.target.value)
    }
    function emailChange(e) {
        setEmail(e.target.value)
    }
    function phoneChange(e) {
        setPhone(e.target.value)
    }


    function sendMailBtn(e) {
        e.preventDefault();
        console.log("log");
        if (firstName && lastName && email && phone) {

            let newMail = {
                firstName,
                lastName,
                email,
                phone
            }

            emailjs
              .sendForm('service_pqmnpj9', 'template_df5rc1g', form.current, {
                publicKey: 'FECv64Biivz0Ng_Lq',
              })
              .then(
                () => {
                  alert("Success")
                  document.getElementById("firstNameInput").value = ""
                  document.getElementById("emailInput").value = ""
                  document.getElementById("lastNameInput").value = ""
                  document.getElementById("phoneInput").value = ""
                  document.getElementById("contact-textarea").value = ""
                },
                (error) => {
                  console.log('FAILED...', error.text);
                },
              );


        } else {
            handleOpen()
        }
    }

    return (
        <>
            <Grid container id="contact-container" >
                <Grid item md={6} xs={12}>
                    <form onSubmit={e => sendMailBtn(e)} ref={form} id='contact-form'>
                        <h2>Let's Work Together</h2>
                        <div id='contact-form-div'>
                            <div>
                                <label>FirstName*</label>
                                <input name='firstname' onChange={firstnameChange} type='text' id='firstNameInput' placeholder='Please Write Your FirstName' />
                                <label>Email*</label>
                                <input name='email' onChange={emailChange} type='email' id='emailInput' placeholder='Please Write Your Email Address' />

                            </div>
                            <div>
                                <label>Lastname*</label>
                                <input name='lastname' onChange={lastnameChange} type='text' id='lastNameInput' placeholder='Please Write Your LastName' />
                                <label>Phone*</label>
                                <input name='phone' onChange={phoneChange} type='number' id='phoneInput' placeholder='Please Write Your Phone Number' />
                            </div>
                        </div>
                        <label className='label' >Description</label>
                        <textarea name='message' placeholder='Please Write Description' id='contact-textarea' />

                        <Button type='submit' style={{ backgroundColor: "rgb(9 , 255 , 159)", color: "black", borderRadius: "100px", fontSize: "12px" }} variant="contained">Send message</Button>
                    </form>
                </Grid>
                <Grid item md={6} xs={12}>
                    <ContactWay icon={<Phone />} title="Phone" data="+98 992 774 6864" />
                    <ContactWay icon={<Email />} title="Email" data="ahmdbakhtiyariii@gmail.com" />
                    <ContactWay icon={<Place />} title="Address" data="Iran-Alborz-Karaj-Baghestan" />
                </Grid>
            </Grid>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography style={{ color: "red", fontWeight: "bold" }} id="modal-modal-title" variant="h6" component="h2">
                        Warning
                    </Typography>
                    <Typography style={{ color: "black" }} id="modal-modal-description" sx={{ mt: 2 }}>
                        You didn't fill the input - Please Try Again
                    </Typography>
                </Box>
            </Modal>
        </>
    )
}
