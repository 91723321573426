import { Grid } from '@mui/material'
import React from 'react'
import './Resume.css'
import Experience from '../Experience/Experience';
import Skills from '../Skills/Skills';
import About from '../About/About/About';
export default function Resume() {

  function btnHandler(Event) {
    switch (Event.target.id) {

      case "EducationBtn": {
        document.getElementById("experience-div").style.display = "block"
        document.getElementById("skills-div").style.display = "none"
        document.getElementById("about-div").style.display = "none"
        break
      }

      case "SkillsBtn": {
        document.getElementById("skills-div").style.display = "block"
        document.getElementById("experience-div").style.display = "none"
        document.getElementById("about-div").style.display = "none"
        break
      }
      
      case "AboutBtn": {
        document.getElementById("about-div").style.display = "block"
        document.getElementById("experience-div").style.display = "none"
        document.getElementById("skills-div").style.display = "none"
        break
      }

      default: {
        console.log("wrong");
      }
    }
  }

  return (
    <Grid container style={{ marginTop: "40px" }}>
      <Grid item md={4} xs={12} id='resume-left-side'>
        <h2>Why hire me?</h2>
        <p>Click buttons to show</p>
        <div>
          <button onClick={(e) => btnHandler(e)} id='EducationBtn'>Education</button>
          <button onClick={(e) => btnHandler(e)} id='SkillsBtn'>Skills</button>
          <button onClick={(e) => btnHandler(e)} id='AboutBtn'>About me</button>
        </div>
      </Grid>
      <Grid item md={8} xs={12}>
        <div id='experience-div'>
          <Experience />
        </div>
        
        <div id='skills-div'>
          <Skills />
        </div>

        
        <div id='about-div'>
          <About />
        </div>

      </Grid>
    </Grid>
  )
}
