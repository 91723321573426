import React from 'react'
import './About.css'
export default function About() {
    return (
        <>
            <h2>About Me</h2>
            <p>Hello I'm Ahmad Bakhtiyari and I'm Front-end Developer,and I'm very passionate and dedicated to my work.
                I have lot of
                acquired the skills and knowledge necessary to make your project a
                success. I enjoy every step the Programming process,from discussion
                and collaboration.
            </p>

            <div id='about-container' >
                <div>
                    <p><span>Birthday:</span> 21 Oct 2000 </p>
                    <p><span>Website: </span> www.ezuiux.com </p>
                    <p><span>Phone:   </span> +98 992 774 6864 </p>
                    <p><span>Study:   </span> Karaj Azad University </p>
                </div>
                <div>
                    <p><span>Degree:</span> Bachelor of Computer Engineering </p>
                    <p><span>Github:</span> github.com/ahmdbakhtiari </p>
                    <p><span>Mail:</span> ahmdbakhtiyariii@gmail.com </p>
                    <p><span>Location:</span> Alborz-Karaj-Baqestan </p>
                </div>
            </div>
        </>
    )
}
