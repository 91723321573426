import React from 'react'

export default function InfoCard({number , title}) {
  return (
    <div style={{display : "flex" , alignItems : "center" }}>
        <p style={{fontSize : "56px" , margin : '0px' , fontWeight : "600" , marginRight : "16px" , width : "80px" , textAlign : "center"}}>{number}</p>
        <p style={{width : "80px" , fontSize : "18px"}}>{title}</p>
    </div>
  )
}
