import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import './Header.css'
const pages = ['Home', 'Services', 'Resume', 'Contact'];

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };


    return (
        <AppBar style={{ backgroundColor: 'transparent', boxShadow: "none", marginTop: "16px" }} position="static">
            <Container style={{ padding: "0px" }} maxWidth="xl">
                <Toolbar disableGutters sx={{ display: "flex", justifyContent: { xs: "block", md: "space-between" } }}>
                    <div id="header">
                        <Link
                            style={{ fontSize: "24px", fontWeight: '600' }}
                            to={'/'}
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            Ahmad<span>{'.'}</span>
                        </Link>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                                        <Link style={{ color: "black" }} to={page} >{page}</Link>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </div>
                    <div>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, alignItems: "center" }}>
                            {pages.map((page) => (
                                <Link
                                    to={page}
                                    key={page}
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: 'white', display: 'block', textTransform: "capitalize" }}
                                >
                                    {page}
                                </Link>
                            ))}
                            <Button id='hireBtn'>
                                <Link to={'/Contact'}>
                                    Hire me
                                </Link>
                            </Button>
                        </Box>

                    </div>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;
