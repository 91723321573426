import React from 'react'
import './ContactWay.css'
export default function ContactWay({title , data , icon}) {
  return (
    <div id='contact-way-container'>
        {icon}
        <div>
            <p>{title}</p>
            <p>{data}</p>
        </div>
    </div>
  )
}
