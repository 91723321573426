import React, { useState } from 'react'
import {SouthEast}  from '@mui/icons-material';
import './ServiceCard.css'
export default function ServiceCard({servicenum,serviceTitle,serviceDescribe}) {
    return (
        
        <div className='service-card-container'>
            <div className='service-card-count'>
                <p>{servicenum}</p>
                <SouthEast />
            </div>
            <h2>{serviceTitle}</h2>
            <p className='service-describe'>{serviceDescribe} </p>
            <hr />
        </div>
    )
}
