import React from 'react'
import HdrStrongIcon from '@mui/icons-material/HdrStrong';
import './ExperienceCard.css'
export default function ExperienceCard({ year, title, describe }) {
    return (
        <div id='experience-card-container'>
            <div>
                <p>{year}</p>
                <h3>{title}</h3>
            </div>
            <div>
                <HdrStrongIcon /> <p>{describe}</p>
            </div>
        </div>
    )
}
