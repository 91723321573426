import React from 'react'
import './Home.css'
import { Download, GitHub, Instagram, LinkedIn  , Telegram} from '@mui/icons-material'
import { Button, Grid } from '@mui/material'
import InfoCard from '../InfoCard/InfoCard'

export default function Home() {
    document.title = "Ezuiux"
    return (
        <div>
            <Grid id='homeContainer' container >

                <Grid item md={6} xs={12}>

                    <p id='career'>Front-end Developer</p>
                    <h1 id='introduce'>Hello I'm <br /> <span>Ahmad Bakhtiyari</span></h1>
                    <p>I can help you to make creative and standard websites </p>
                    <p>have a look at my portfolios</p>
                    <div id='portfolioContainer'>

                        <a target="_blank" rel="noopener noreferrer" href='./cv.pdf' id='cvBtn' variant="outlined" endIcon={<Download />}>
                            Download CV
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href='https://github.com/ahmdbakhtiari/'><GitHub /></a>
                        <a target="_blank" rel="noopener noreferrer" href='https://t.me/ahmd_bakhtiyari'><Telegram /></a>
                    </div>
                </Grid>

                <Grid id='imageGrid' item md={6} xs={12} style={{ direction: "rtl" }}>
                    <div id='imageDiv'>

                    </div>
                    <img src='./images/ahmadbakhtiyari.png'>

                    </img>
                </Grid>

            </Grid>

            <Grid container style={{ marginTop: "40px" }}>

                <Grid item md={3} sm={6} xs={12} >
                    <InfoCard number={2} title={"Years Of Experience"} />
                </Grid>

                <Grid item md={3} sm={6} xs={12} >
                    <InfoCard number={10} title={"Projects Completed"} />
                </Grid>

                <Grid item md={3} sm={6} xs={12} >
                    <InfoCard number={4} title={"Technologies Mastered"} />
                </Grid>

                <Grid item md={3} sm={6} xs={12} >
                    <InfoCard number={30} title={"Code Commited"} />
                </Grid>

            </Grid>
        </div>
    )
}
