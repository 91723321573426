import React from 'react'
import ExperienceCard from '../ExperienceCard/ExperienceCard'
import './Experience.css'
export default function Experience() {
  const experiences = [
    {year : "2022 - 2023" , title : "HTML" , describe : "Source : Self-Taught"},
    {year : "2022 - 2023" , title : "CSS" , describe : "Source : Self-Taught"},
    {year : "2022 - 2023" , title : "JavaScript" , describe : "Source : Sabzlearn course"},
    {year : "2023 - 2024" , title : "ReactJs" , describe : "Source : Sabzlearn course"},
    {year : "2024 - 2025" , title : "NextJs" , describe : "Source : Self-Taught"},
    {year : "2023 - 2024" , title : "Tailwind" , describe : "Source : Youtube course"},
    {year : "2023 - 2024" , title : "Sass" , describe : "Source : Youtube course"},
    {year : "2023 - 2024" , title : "Bootstrap" , describe : "Source : Self-Taught"},
    {year : "2023 - 2024" , title : "Git" , describe : "Source : Sabzlearn course"},
    {year : "2021 - 2022" , title : "UIUX Design" , describe : "Source : 7learn course"},
    {year : "2021 - 2022" , title : "Figma" , describe : "Source : Self-Taught"},
    {year : "2021 - 2022" , title : "Wordpress" , describe : "Source : Youtube course"},
    {year : "2020 - 2024" , title : "Bachelor of Computer Engineering" , describe : "University : Azad University Karaj"},
  ]
  return (
    <>
      <h2>My Education</h2>
      <p>There is my education with references</p>
      <div id='experience-container'>
        {experiences.map((experience , index) => {
          return (
            <ExperienceCard {...experience} key={index}/>
          )
        })}
      </div>
    </>
  )
}
