import React from 'react'
import { Tooltip, IconButton } from '@mui/material'
import './Skills.css'
export default function Skills() {
    return (
        <>
            <h2>My Skills</h2>
            <p>My Skills in front-end programming</p>
            <div id='skill-container'>
                <Tooltip title="HTML">
                    <img src='./html.svg' />
                </Tooltip>
                <Tooltip title="CSS">
                    <img src='./css.svg' />
                </Tooltip>
                <Tooltip title="Javscript">
                    <img src='./js.svg' />
                </Tooltip>
                <Tooltip title="React">
                    <img src='./react.svg' />
                </Tooltip>

                <Tooltip title="NextJs">
                    <img src='./nextjs.svg' />
                </Tooltip>

                <Tooltip title="Tailwind">
                    <img src='./tailwindicon.svg' />
                </Tooltip>

                <Tooltip title="Sass">
                    <img src='./sass.svg' />
                </Tooltip>

                <Tooltip title="Bootstrap">
                    <img src='./bootstrap.svg' />
                </Tooltip>

                <Tooltip title="Git">
                    <img src='./git.svg' />
                </Tooltip>

                <Tooltip title="Figma">
                    <img src='./figma.svg' />
                </Tooltip>
            </div>
        </>
    )
}
